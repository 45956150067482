import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.reduce";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.index-of";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import cookies from "./util.cookies";
import log from "./util.log";
import db from "./util.db";
import CONSTANT from "./util.const";
import Setting from '@/setting';

// 品牌列表（暂时写死）
var TENANTID_LIST2 = [{
  label: '很久以前羊肉串',
  value: 5698006849421313,
  userName: 'admin'
}, {
  label: '吉祥馄饨',
  value: 54,
  userName: 'admin54'
}, {
  label: '牛约堡',
  value: 5698006849421314,
  userName: 'admin14'
}, {
  label: '测试12',
  value: 12,
  userName: 'admin12'
}];
var util = _objectSpread(_objectSpread({
  cookies: cookies,
  log: log,
  db: db
}, CONSTANT), {}, {
  TENANTID_LIST: [],
  TENANTID_LIST2: TENANTID_LIST2
});
function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function (_ref) {
  var title = _ref.title,
    count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};

/**
 * 函数防抖优化版
 * @param {Function} fn 回调函数
 * @param {Number} delay 延迟时间
 */
util.debounce = function (fn) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

/**
 * 去除请求参数中为空的参数
 * @param {*} data
 * @returns
 */
// util.simplifyObject = (data) => {
//     let isObject = Object.prototype.toString.call(data) === '[object Object]'
//     if (isObject) {
//         Object.keys(data).forEach(key => {
//             if (data[key] === '') {
//                 delete data[key]
//             } else {
//                 data[key] = util.simplifyObject(data[key])
//             }
//         })
//     }
//     return data
// }

/**
 * 对象数组中所有属性名首字母转为小写的递归方法
 * @param {*} data
 * @returns
 */
util.convertPropertyNamesToLowerCase = function (data) {
  var isArray = Object.prototype.toString.call(data) === '[object Array]';
  var isObject = Object.prototype.toString.call(data) === '[object Object]';
  if (isObject) {
    Object.keys(data).forEach(function (key) {
      var newKey = key.charAt(0).toLowerCase() + key.slice(1);
      data[newKey] = util.convertPropertyNamesToLowerCase(data[key]);
      if (key !== newKey) {
        delete data[key];
      }
    });
  }
  if (isArray) {
    data = data.map(function (item) {
      return util.convertPropertyNamesToLowerCase(item);
    });
  }
  return data;
};
util.getUUID = function () {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  }
  if (window.crypto.randomUUID) {
    name += window.crypto.randomUUID();
  } else {
    name += guid();
  }
  return name;
};
util.getNumID = function () {
  var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '97';
  // 生成一个介于0到1之间的随机数
  var randomNumber = Math.random();
  // 将随机数转换为字符串，并取其前16位
  var digits = randomNumber.toString().slice(4, 18);
  // 如果生成的数字位数不足16位，则在前面补0
  return Number(prefix + (digits.length === 14 ? digits : '0'.repeat(14 - digits.length) + digits));
};

/**
 * 数据分组
 * @param {*} items 原始数组
 * @param {*} key 按照哪个字段分组
 * @returns
 */
util.classify = function (items, key) {
  return items.reduce(function (acc, item) {
    var category = item[key];
    if (!acc[category]) acc[category] = [];
    acc[category].push(item);
    return acc;
  }, {});
};
// 数据分组转成二维数组结构
util.classifyToArray = function (items, key) {
  var itemsMap = util.classify(items, key);
  return Object.keys(itemsMap).map(function (key) {
    return {
      'key': key,
      children: itemsMap[key]
    };
  });
};

/**
 * 下载文件
 * @param {*} url
 * @returns
 */
util.downloadFile = function (url) {
  var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
};
/**
 * 交换数组元素
 */
util.swapArrayElements = function (arr, indexA, indexB) {
  var temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
};
function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }
  setTimeout(task, 16);
}
export { requestAnimation };
export default util;