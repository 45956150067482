import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'marketing-center';
var pre = 'sales';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  activePath: "/".concat(basePre, "/").concat(pre, "/promotion")
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/salesList/index"),
  title: '促销活动列表',
  name: "".concat(pre, "-list"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '促销活动列表'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/salesList/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullReduction/index"),
  title: '满减',
  name: "".concat(pre, "-fullReduction"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '满减'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullReduction/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullReduction/detail"),
  title: '新增活动',
  name: "".concat(pre, "-fullReduction-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullReduction/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullReduction-detail/:id?"),
  title: '活动详情',
  name: "".concat(pre, "-fullReduction-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullReduction/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullDiscount/index"),
  title: '满折',
  name: "".concat(pre, "-fullDiscount"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '满折'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullDiscount/index.vue")
}, {
  path: "/".concat(pre, "/").concat(pre, "/fullDiscount/detail"),
  title: '新增活动',
  name: "".concat(pre, "-fullDiscount-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增满活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullDiscount/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullDiscount-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-fullDiscount-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullDiscount/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullGift/index"),
  title: '满赠',
  name: "".concat(pre, "-fullGift"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '满赠'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullGift/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullGift/detail"),
  title: '新增活动',
  name: "".concat(pre, "-fullGift-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullGift/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/fullGift-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-fullGift-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/fullGift/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/specialActivities/index"),
  title: '特价',
  name: "".concat(pre, "-specialActivities"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '特价'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/specialActivities/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/specialActivities/detail"),
  title: '新增活动',
  name: "".concat(pre, "-specialActivities-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/specialActivities/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/specialActivities-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-specialActivities-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/specialActivities/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/increasePrice/index"),
  title: '加价换购',
  name: "".concat(pre, "-increasePrice"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '加价换购'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/increasePrice/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/increasePrice/detail"),
  title: '新增活动',
  name: "".concat(pre, "-increasePrice-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/increasePrice/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/increasePrice-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-increasePrice-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/increasePrice/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/randomReduction/index"),
  title: '随机立减',
  name: "".concat(pre, "-randomReduction"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '随机立减'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/randomReduction/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/randomReduction/detail"),
  title: '新增活动',
  name: "".concat(pre, "-randomReduction-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/randomReduction/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/randomReduction/detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-randomReduction-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/randomReduction/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/givePresent/index"),
  title: '搭赠',
  name: "".concat(pre, "-givePresent"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '搭赠'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/givePresent/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/givePresent/detail"),
  title: '新增活动',
  name: "".concat(pre, "-givePresent-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/givePresent/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/givePresent-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-givePresent-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/givePresent/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/secondDiscount/index"),
  title: '第二份打折',
  name: "".concat(pre, "-secondDiscount"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '第二份打折'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/secondDiscount/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/secondDiscount/detail"),
  title: '新增活动',
  name: "".concat(pre, "-secondDiscount-add"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '新增活动'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/secondDiscount/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/secondDiscount-detail/:id"),
  title: '活动详情',
  name: "".concat(pre, "-secondDiscount-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    title: '活动详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/secondDiscount/detail.vue")
}, {
  path: "/".concat(basePre, "/promotion-control-detail/:type/:id?"),
  title: '促销管控',
  name: 'promotion-control-detail',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/promotion-control"),
    title: '促销管控详情'
  }),
  component: "pages/".concat(basePre, "/promotion-control/detail.vue")
}];