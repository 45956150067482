import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'operation-center';
var pre = 'booking';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  refresh: true,
  activePath: "/".concat(basePre, "/").concat(pre, "/order")
};
export default [
// 预订管理
{
  path: "/".concat(basePre, "/").concat(pre, "/detail/:id"),
  title: '订单详情',
  name: "".concat(pre, "-order-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/order"),
    title: '预订订单详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/order/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/setting-detail/:type/:id"),
  title: '预订设置',
  name: "".concat(pre, "-setting-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/setting"),
    title: '预订设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/setting/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/setting-tenantSet"),
  title: '品牌设置',
  name: "".concat(pre, "-setting-tenantSet"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/setting"),
    title: '品牌设置'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/setting/tenantSet.vue")
}];