/**
 * 常量
 */
var CONSTANT = {};

// 全局颜色值列表
CONSTANT.COLORS = ['#2D8CF0', '#00B0FD', '#00CDE7', '#00E4B9', '#91F389', '#F9F871', '#F9CC71', '#F9B071', '#F99B71', '#F97F71'];
CONSTANT.LOG_TYPES = [{
  label: '支付宝',
  value: 1
}, {
  label: '百度',
  value: 2
}, {
  label: '集点',
  value: 3
}, {
  label: '扫呗支付',
  value: 4
}, {
  label: 'streffy支付',
  value: 5
}, {
  label: '易佰',
  value: 6
}, {
  label: '5i会员',
  value: 7
}, {
  label: '会员',
  value: 8
}, {
  label: '哗啦啦订单中心',
  value: 9
}, {
  label: '第三方-抖音',
  value: 10
}, {
  label: '第三方-微信',
  value: 11
}, {
  label: '斯慧订单',
  value: 12
}, {
  label: '客如云订单',
  value: 13
}, {
  label: '第三方-快手',
  value: 14
}];
CONSTANT.EVENT_TYPES = [{
  label: '提交订单',
  value: 1
}, {
  label: '支付回调',
  value: 2
}, {
  label: '推送订单',
  value: 3
}, {
  label: 'Pos/订单中心回调',
  value: 4
}, {
  label: '取消订单',
  value: 5
}, {
  label: '申请退款',
  value: 6
}, {
  label: '退款回调',
  value: 7
}, {
  label: '会员卡消费',
  value: 8
}, {
  label: '会员卡撤销消费',
  value: 9
}, {
  label: '会员卡储值',
  value: 10
}, {
  label: '更新订单',
  value: 11
}, {
  label: '发劵',
  value: 12
}, {
  label: '劵作废',
  value: 13
}];
CONSTANT.ORDER_STATUS = [{
  label: '待支付',
  value: '10'
}, {
  label: '已支付',
  value: '20'
}, {
  label: '已签到/已预约/已推送pos/三方配送',
  value: '30'
}, {
  label: '门店已接单',
  value: '40'
}, {
  label: '已制作',
  value: '50'
}, {
  label: '已申请退款',
  value: '60'
}, {
  label: '退款已审核',
  value: '65'
}, {
  label: '已取消',
  value: '70'
}, {
  label: '异常',
  value: '80'
}, {
  label: '已部分退款',
  value: '85'
}, {
  label: '已退款',
  value: '90'
}, {
  label: '已完成',
  value: '100'
}, {
  label: '支付失败',
  value: '14'
}];

// ### 客户管理
// 用户来源平台列表
// 用户来源
// 微信 101
// 公众号 001
// 小程序 002
// 微信公众号 101001
// 微信公众号 101002
// 支付宝 102
// 抖音 103
// 快手 104
// 小红书 105
// app  201
CONSTANT.SOURCE_FROM_LIST = [{
  label: '微信',
  value: '101'
}, {
  label: '公众号',
  value: '001'
}, {
  label: '小程序',
  value: '002'
}, {
  label: '微信公众号',
  value: '101001'
}, {
  label: '微信公小程序',
  value: '101002'
}, {
  label: '支付宝',
  value: '102'
}, {
  label: '支付宝小程序',
  value: '102002'
}, {
  label: '抖音',
  value: '103'
}, {
  label: '抖音小程序',
  value: '103002'
}, {
  label: '快手',
  value: '104'
}, {
  label: '快手小程序',
  value: '104002'
}, {
  label: '小红书',
  value: '105'
}, {
  label: '小红书小程序',
  value: '105002'
}, {
  label: 'app',
  value: '201'
}];
CONSTANT.SOURCE_FROM_LIST2 = [{
  label: '微信小程序',
  value: '1'
}, {
  label: '支付宝小程序',
  value: '2'
}, {
  label: 'Pos',
  value: '3'
}, {
  label: '大屏',
  value: '4'
}, {
  label: 'PDA',
  value: '5'
}, {
  label: '抖音小程序',
  value: '6'
}, {
  label: '快手小程序',
  value: '7'
}];

// 用户来源列表
CONSTANT.TYPE_FROM_LIST = [{
  label: '线上',
  value: 1
}, {
  label: '线下',
  value: 2
}];

// 国家列表
CONSTANT.COUNTRY_LIST = [{
  label: '中国',
  value: 'China'
}];

// 性别列表
CONSTANT.SEX_LIST = [{
  label: '男',
  value: 1
}, {
  label: '女',
  value: 2
}, {
  label: '未知',
  value: 0
}];

// 渠道列表
// 20240927 申银屏 1.微信小程序 2.支付宝小程序 3Pos 4.大屏 5.Pad 6抖音
CONSTANT.CHANNEL_LIST = [{
  label: '微信小程序',
  value: 1,
  icon: 'icon-weixinxiaochengxu'
}, {
  label: '支付宝小程序',
  value: 2,
  icon: 'icon-zhifubaoxiaochengxu'
}, {
  label: 'POS',
  value: 3,
  icon: 'icon-POS_1'
}, {
  label: '大屏',
  value: 4,
  icon: 'icon-daping'
}, {
  label: 'PAD',
  value: 5,
  icon: 'icon-PAD_1'
}, {
  label: '抖音小程序',
  value: 6,
  icon: 'icon-PAD_1'
}, {
  label: '快手小程序',
  value: 7,
  icon: 'icon-PAD_1'
}];
// 消费渠道列表
CONSTANT.SOURCE_ORDER = [{
  label: '点餐订单',
  value: '1',
  icon: '',
  children: [{
    label: '平台支付',
    value: '1.a'
  }, {
    label: '储值本金支付',
    value: '1.b'
  }, {
    label: '储值赠金支付',
    value: '1.c'
  }, {
    label: '积分支付',
    value: '1.d'
  }]
}, {
  label: '储值订单',
  value: '2',
  icon: '',
  children: []
}, {
  label: '券包订单',
  value: '3',
  icon: '',
  children: []
}, {
  label: '积分商城订单',
  value: '4',
  icon: '',
  children: []
}, {
  label: '礼品卡订单',
  value: '5',
  icon: '',
  children: []
}];

// ### 会员卡方案
// 会员卡类型
CONSTANT.CARD_TYPES = [{
  label: '普通',
  value: 1
}, {
  label: '企业',
  value: 2
}];

// 礼品卡类型
CONSTANT.GIFT_CARD_TYPES = [{
  label: '礼金卡',
  value: 1
}];

// 券模板
CONSTANT.COUPON_TYPE_LIST = [{
  type: 1,
  title: '代金券',
  des: '适用于减免优惠',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_1.png'
}, {
  type: 2,
  title: '折扣券',
  des: '适用于设置折扣优惠',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_2.png'
}, {
  type: 3,
  title: '商品优惠券',
  des: '适用于设置商品的金额减免',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_3.png'
}, {
  type: 6,
  title: '商品兑换券',
  des: '适用于设置商品的兑换优惠',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_4.png'
}, {
  type: 4,
  title: '商品折扣券',
  des: '适用于设置商品的金额折扣',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_5.png'
}, {
  type: 5,
  title: '买赠券',
  des: '适用于设置买赠优惠',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_6.png'
}, {
  type: 7,
  title: '次券',
  des: '适用于多次数多次消费',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/coupon/coupon_type_7.png'
}];

// 券模版标签
CONSTANT.COUPON_TAG_LIST = [{
  label: '过期退',
  value: 1
}, {
  label: '随时退',
  value: 2
}, {
  label: '免预约',
  value: 3
}];

// 券模版业务属性
CONSTANT.COUPON_BIZTYPE_LIST = [{
  label: '门店业务',
  value: 1
}];

// 券模版业务属性
// 业务类型 1、自提，2 堂食，3、外卖 4 外带 5 拼单 6 积分商品订单
CONSTANT.SCENE_LIST = [{
  label: '自提',
  value: 2
}, {
  label: '堂食',
  value: 1
}, {
  label: '外卖',
  value: 3
}, {
  label: '外带',
  value: 4
}, {
  label: '拼单',
  value: 5
}, {
  label: '积分商品订单',
  value: 6
}];
// 券模版业务属性
CONSTANT.ORDER_SCENE_LIST = [{
  label: '自提',
  value: 2
}, {
  label: '堂食',
  value: 1
}, {
  label: '外卖',
  value: 3
}];

// 券模版 优惠券状态
CONSTANT.COUPON_STATUS_LIST = [{
  label: '可使用',
  value: 1
}, {
  label: '已核销',
  value: 2
}, {
  label: '已过期',
  value: 3
}, {
  label: '已作废',
  value: 4
}, {
  label: '已出售',
  value: 5
}, {
  label: '出售中',
  value: 6
}, {
  label: '转赠中',
  value: 7
}, {
  label: '已转赠',
  value: 8
}, {
  label: '冻结',
  value: 9
}];

// 券模版 投放批次状态
CONSTANT.BATCH_STATUS_LIST = [{
  label: '全部',
  value: 0
}, {
  label: '未开启',
  value: 1
}, {
  label: '进行中',
  value: 2
}, {
  label: '已结束',
  value: 3
}, {
  label: '已失效',
  value: 4
}];
CONSTANT.WEEK_LIST = [{
  label: '周一',
  value: '1'
}, {
  label: '周二',
  value: '2'
}, {
  label: '周三',
  value: '3'
}, {
  label: '周四',
  value: '4'
}, {
  label: '周五',
  value: '5'
}, {
  label: '周六',
  value: '6'
}, {
  label: '周日',
  value: '0'
}];

// 基本档
// 餐段
CONSTANT.MEAL_PERIOD = [{
  label: '餐段1',
  value: 1
}, {
  label: '餐段2',
  value: 2
}, {
  label: '餐段3',
  value: 3
}, {
  label: '餐段4',
  value: 4
}, {
  label: '餐段5',
  value: 5
}];
// 门店经营状态
CONSTANT.STORE_OPERATIONAL_STATUS = [{
  label: '运营中',
  value: 1
}, {
  label: '装修中',
  value: 2
}, {
  label: '已关闭',
  value: 3
}];
// 门店营业状态
CONSTANT.STORE_OPENING_STATUS = [{
  label: '营业中',
  value: 1
}, {
  label: '休息中',
  value: 2
}, {
  label: '打烊',
  value: 3
}];
// 门店运营模式
CONSTANT.STORE_MEAL_TYPE = [{
  label: '快餐',
  value: 1
}, {
  label: '正餐',
  value: 2
}, {
  label: '中餐',
  value: 4
}, {
  label: '自助餐',
  value: 8
}, {
  label: '其它',
  value: 16
}];
// 门店经营方式
CONSTANT.STORE_TYPE = [{
  label: '直营',
  value: 1
}, {
  label: '加盟',
  value: 2
}, {
  label: '联营',
  value: 3
}];
// 服务闭环的角色权限
CONSTANT.SERVE_PERMISSION = [{
  label: '商品闭环',
  value: 1
}, {
  label: '服务闭环',
  value: 2
}, {
  label: '结束',
  value: 3
}, {
  label: '优惠结账相关',
  value: 4
}];

// 预订 订单状态 状态，10-未到店，20-已到店，30-已取消，40-已完成（开台）
CONSTANT.BOOKING_STATUS = [{
  label: '未到店',
  value: 10
}, {
  label: '已到店',
  value: 20
}, {
  label: '已取消',
  value: 30
}, {
  label: '已完成',
  value: 40
}];
// 预订/排队 订单来源 1、pad, 11、微信小程序，21：支付宝小程序, 31:线下扫码
CONSTANT.BOOKING_SOURCE = [{
  label: 'PDA',
  value: 1
}, {
  label: '微信',
  value: 11
}, {
  label: '支付宝',
  value: 21
}, {
  label: '线下扫码',
  value: 31
}];
// 预订--排队 订单状态 状态，10、排队 20、取消排队 30、叫号 40、用餐 50、过号
CONSTANT.QUEUING_STATUS = [{
  label: '排队中',
  value: 10
}, {
  label: '已取消',
  value: 20
}, {
  label: '叫号中',
  value: 30
}, {
  label: '已完成',
  value: 40
}, {
  label: '已过号',
  value: 50
}];

// 菜品类型
CONSTANT.PRODUCT_TYPE = [{
  label: '普通菜',
  value: 1
}, {
  label: '套餐',
  value: 2
}];
// 属性分组类型
CONSTANT.GROUP_TYPE = [
// { label: '规格', value: 1 },
{
  label: '口味',
  value: 2
}, {
  label: '做法',
  value: 3
}, {
  label: '配菜',
  value: 4
}, {
  label: '加料',
  value: 5
}];
// 抖音新增商品规格组 规格
CONSTANT.TIKTOK_GROUP_TYPE = [{
  label: '份',
  value: 1
}, {
  label: '个',
  value: 2
}, {
  label: '盒',
  value: 3
}, {
  label: '根',
  value: 4
}, {
  label: '袋',
  value: 5
}, {
  label: '只',
  value: 6
}, {
  label: '支',
  value: 7
}, {
  label: '件',
  value: 8
}];

// 订单退款原因
CONSTANT.ORDER_REFUND_REASON = [{
  label: '用户信息错误',
  value: 'UserInfoError',
  code: '501'
}, {
  label: '商品已经售完',
  value: 'ProductSoldOut',
  code: '502'
}, {
  label: '商家已经打烊',
  value: 'MerchantClosed',
  code: '503'
}, {
  label: '商家现在太忙',
  value: 'MerchantTooBusy',
  code: '504'
}, {
  label: '超出配送范围',
  value: 'OutOfDeliveryRange',
  code: '505'
}, {
  label: '无骑手接单',
  value: 'NoRiderAccepted',
  code: '506'
}, {
  label: '用户自身原因要求取消',
  value: 'UserRequestedCancel',
  code: '507'
}];

// 订单退款状态
CONSTANT.REFUND_STATUS = [{
  label: '顾客发起退款',
  value: '10',
  statusName: '退款申请中'
}, {
  label: '商家同意退款',
  value: '20',
  statusName: '商家同意退款'
}, {
  label: '商家拒绝退款',
  value: '30',
  statusName: '商家拒绝退款'
}, {
  label: '商家已发起退款',
  value: '40',
  statusName: '商家已发起退款'
}, {
  label: '退款中',
  value: '70',
  statusName: '退款中'
}, {
  label: '退款失败',
  value: '80',
  statusName: '退款失败'
}, {
  label: '已退款成功',
  value: '99',
  statusName: '退款成功'
}];

// 订单退款状态
CONSTANT.FULFILLTYPE_STATUS = [{
  label: '先买单',
  value: 1
}, {
  label: '后约单',
  value: 2
}, {
  label: '立即配送',
  value: 3
}, {
  label: '一品双开',
  value: 4
}];

// 订单退款状态
CONSTANT.ROLETYPE = [{
  label: '所有',
  value: 'ALL'
}, {
  label: '门店',
  value: 'STORE'
}];

// 促销类型
CONSTANT.PROTION = [{
  label: '会员价',
  value: 0
}, {
  label: '满减',
  value: 1
}, {
  label: '满折',
  value: 2
}, {
  label: '满赠',
  value: 3
}, {
  label: '特价活动',
  value: 4
}, {
  label: '加价购',
  value: 5
}, {
  label: '随机立减',
  value: 6
}, {
  label: '第二份打折',
  value: 7
}, {
  label: '搭赠',
  value: 8
}];
CONSTANT.Applicable_Users_List = [{
  label: '不限制',
  value: 1
}, {
  label: '仅会员',
  value: 2
}, {
  label: '非会员',
  value: 3
}];

// 券发出/获取方式
CONSTANT.COUPONWAY = [{
  label: '未知',
  value: 0
}, {
  label: '后台',
  value: 1
}, {
  label: '消费送礼',
  value: 2
}, {
  label: '摇奖活动',
  value: 3
}, {
  label: '积分抽奖',
  value: 4
}, {
  label: '免费领取',
  value: 5
}, {
  label: '推荐有礼',
  value: 6
}, {
  label: '储值送礼',
  value: 7
}, {
  label: '开卡礼',
  value: 8
}, {
  label: '生日礼',
  value: 9
}, {
  label: '群发送礼',
  value: 10
}, {
  label: '批量导入',
  value: 11
}, {
  label: '购买权益包',
  value: 12
}, {
  label: '完善资料送礼',
  value: 13
}, {
  label: '升级送礼',
  value: 14
}, {
  label: '累计消费送礼',
  value: 15
}, {
  label: '评价送礼',
  value: 16
}, {
  label: '集点卡',
  value: 17
}, {
  label: '签到',
  value: 18
}, {
  label: '购买券包',
  value: 19
}, {
  label: '转赠',
  value: 20
}, {
  label: '抖音',
  value: 21
}, {
  label: '快手',
  value: 22
}, {
  label: '小红书',
  value: 23
}];

// 启用状态
CONSTANT.OPENTYPE = [{
  label: '全部',
  value: 0
}, {
  label: '启用',
  value: 1
}, {
  label: '禁用',
  value: 2
}];

// 储值活动方案类型
CONSTANT.RECHARGE_SCHEME_TYPE = [{
  label: '固定储值',
  value: 1
}, {
  label: '推荐储值',
  value: 2
}];

// 储值流水类型
CONSTANT.RECHARGE_TYPE = [{
  label: '储值',
  value: 1
}, {
  label: '订单消费',
  value: 2
}, {
  label: '消费退款',
  value: 3
}, {
  label: '冻结',
  value: 4
}, {
  label: '解冻',
  value: 5
}, {
  label: '调账',
  value: 6
}, {
  label: '活动赠余额',
  value: 7
}, {
  label: '储值退款',
  value: 8
}, {
  label: '初始转入',
  value: 9
}];

// 储值流水类型
CONSTANT.POINTS_TYPE = [{
  label: '会员消费',
  value: 1
}, {
  label: '会员退款',
  value: 2
}, {
  label: '等级消费权益',
  value: 3
}, {
  label: '等级升级权益',
  value: 4
}, {
  label: '等级生日权益',
  value: 5
}, {
  label: '等级会员日权益',
  value: 6
}, {
  label: '营销弹屏有礼',
  value: 7
}, {
  label: '调账',
  value: 10
}];

// 支付方式 1、微信小程序，2：支付宝小程序
CONSTANT.PAY_WEY_LIST = [{
  label: '微信',
  value: 1
}, {
  label: '支付宝',
  value: 2
}];

// 积分商城商品类型
CONSTANT.POINTS_PRODUCT_TYPE = [{
  type: 1,
  title: '虚拟商品',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/pointsMall/product_1.png'
}, {
  type: 2,
  title: '实物/线下商品',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/pointsMall/product_2.png'
}, {
  type: 3,
  title: 'API对接商品',
  icon: 'https://image.streffy.com/StreffyAdminImage/StreffyMiddleOffice/image/pointsMall/product_3.png'
}];

// 积分商城商品类型
CONSTANT.POINTS2_PRODUCT_TYPE = {
  1: '虚拟商品',
  2: '实物/线下商品',
  3: 'API对接商品'
};

// 积分商城兑换方式
CONSTANT.POINTS_EXCHANGE_TYPE = {
  1: '积分',
  2: '现金',
  3: '积分/现金'
};

// 积分商城订单状态
CONSTANT.POINTS_ORDER_STATUS = {
  1: '待支付',
  2: '已取消',
  3: '兑换成功',
  4: '已退款'
};

// 订单用户支付记录总支付状态枚举 （谢老板规定的总支付状态与子项支付项状态枚举不一样）
CONSTANT.RECORDS_STATUS_STR = {
  0: '未支付',
  2: '支付中',
  10: '支付成功',
  20: '支付取消',
  30: '支付失败',
  40: '退款成功',
  50: '部分退款',
  60: '退款失败'
};

// 订单用户支付记录子项支付状态枚举 （谢老板规定的总支付状态与子项支付项状态枚举不一样）
CONSTANT.ACTIONS_STATUS_STR = {
  10: '未支付、待支付',
  20: '支付成功',
  30: '支付失败',
  40: '已退款',
  50: '退款失败',
  60: '已支付、核验成功',
  70: '已支付、核验失败'
};
export default CONSTANT;