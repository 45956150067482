import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Description: 卡券中心-路由配置
 * @Author: rhd
 * @Date: 2024-08-01 15:51:46
 * @LastEditors: rhd
 * @LastEditTime: 2024-09-13 11:37:05
 */
var basePre = 'coupon-center';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  refresh: true,
  activePath: "/".concat(basePre, "/coupon-template")
};
export default [{
  path: "/".concat(basePre, "/coupon-template-edit/:templateType/:type/:id?"),
  title: '新增优惠券',
  name: "".concat(basePre, "-template-edit"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增优惠券'
  }),
  component: "pages/".concat(basePre, "/coupon-template-edit")
}, {
  path: "/".concat(basePre, "/coupon-template-detail/:id"),
  title: '优惠券详情',
  name: "".concat(basePre, "-template-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '优惠券详情'
  }),
  component: "pages/".concat(basePre, "/coupon-template-detail")
}, {
  path: "/".concat(basePre, "/coupon-launch-edit/:type/:id?"),
  title: '新增券投放',
  name: "".concat(basePre, "-launch-edit"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/coupon-launch"),
    title: '新增券投放'
  }),
  component: "pages/".concat(basePre, "/coupon-launch/launch-edit.vue")
}];